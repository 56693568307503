<template>
  <div class="wrap">
    <div id="popup" :class="['overlay', {'popup-active': popupSettings.popupActive}]">
      <div class="popup">
        <h2>{{popupSettings.popupHeading}}</h2>
        <a
          @click.prevent="() => {popupSettings.popupActive = !popupSettings.popupActive}"
          class="close"
          href="#"
        >&times;</a>
        <div class="content">{{popupSettings.popupMessage}}</div>
      </div>
    </div>

    <form action class="feedback-form">
      <div class="form-group">
        <h2>Поставте нам Ваше запитання</h2>
      </div>
      <div class="form-group">
        <input type="text" placeholder="ВАШЕ ІМ'Я" name="name" id v-model="name" />
        <input type="text" placeholder="ВАШ ТЕЛЕФОН" name="phone" id v-model="phone" />
        <input type="text" placeholder="ВАШ EMAIL" name="email" id v-model="email" />
      </div>
      <div class="form-group">
        <textarea name="question" placeholder="ВАШЕ ЗАПИТАННЯ" id v-model="question" rows="2"></textarea>
        <button @click.prevent="sendFeedback()" class="send-feedback">Поставити запитання</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "FeedBackForm",
  data: () => ({
    name: "",
    phone: "",
    email: "",
    question: "",
    popupSettings: {
      popupActive: false,
      popupHeading: "",
      popupMessage: ""
    }
  }),
  methods: {
    async sendFeedback() {
      try {
        console.log(this.name, this.email, this.phone, this.question);
        const response = await fetch("/api/v1/email/sendQuestion/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            phone: this.phone,
            question: this.question
          })
        });
        const data = await response.json();
        if (data.status === "success") {
          this.clearFeedbackForm();
          this.showPopup("success");
        } else {
          this.showPopup("error");
        }
      } catch {
        this.showPopup("error");
      }
    },
    clearFeedbackForm() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.question = "";
    },
    showPopup(status) {
      status === "success"
        ? (this.popupSettings.popupHeading = "Успіх")
        : (this.popupSettings.popupHeading = "Помилка");

      status === "success"
        ? (this.popupSettings.popupMessage =
            "Ваше запитання доставлено. Незабаром з вами звяжуться")
        : (this.popupSettings.popupMessage =
            "Сталася помилка. Перевірте правильність данних та спробуйте пізніше ще раз.");

      this.popupSettings.popupActive = true;
    }
  }
};
</script>
<style scoped>
.wrap {
  width: 100%;
  max-width: 1250px;
  margin: 10vh auto 24vh auto;
}

.feedback-form {
  width: calc(100% - 30px);
  margin: auto;
  height: auto;
  border-radius: 10px;
  background: #afd8f3;
  box-shadow: 3px 5px 9px 0 rgba(0, 0, 0, 0.36),
    5px 8px 18px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.form-group {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px auto;
}

.form-group h2 {
  font-size: 30px;
}

.form-group input,
.form-group textarea {
  border: none;
  min-height: 40px;
  font-size: 17px;
  width: 300px;
  border-radius: 4px;
  padding: 5px;
}

.form-group textarea {
  width: 700px;
}

.form-group .send-feedback {
  border: none;
  width: 300px;
  min-height: 40px;
  border-radius: 4px;
  padding: 5px;
  font-size: 17px;
  /* background: #3f4c73; */
  /* background: rgb(41, 76, 204); */
  /* background: #fe031cc0; */
  background: #294ccc;
  color: white;
  transition: 0.3s;
  text-transform: uppercase;
}

.form-group .send-feedback:hover {
  background: #e0e6eb;
  color: black;
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.32),
    4px 6px 15px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1130px) {
  .form-group h2 {
    font-size: 26px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 16px;
    width: 240px;
    padding: 4px;
  }

  .form-group textarea {
    width: 520px;
  }

  .form-group .send-feedback {
    width: 240px;
    padding: 4px;
    font-size: 16px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 830px) {
  .form-group {
    flex-direction: column;
    margin: 15px auto 0 auto;
  }

  .form-group h2 {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .form-group input,
  .form-group textarea {
    min-height: 35px;
    width: 100%;
    margin-bottom: 15px;
  }

  .form-group textarea {
    min-height: 70px;
    margin-bottom: 25px;
  }

  .form-group .send-feedback {
    width: 240px;
    padding: 4px;
    margin-left: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 999999;
}
.overlay.popup-active {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06d85f;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}
</style>