<template>
  <header>
    <div class="intro-image"></div>
    <div class="box">
      <div style="background:#d0d6dB;">
        <div class="wrap-box">
          <nav class="nav">
            <div class="nav-title-logo"></div>
            <!-- <div class="nav-title">
          <div class="title-logo"></div>
          <div class="title-text">
            <h1>ЦПМСД №2</h1>
            <span>деснянського района м. Києва</span>
          </div>
            </div>-->
            <ul class="nav-links">
              <li>
                <router-link class="active" to="/">Головна</router-link>
              </li>
              <li>
                <router-link to="/public-documents">Публічна інформація</router-link>
              </li>
              <li>
                <router-link to="/contacts">Контакти</router-link>
              </li>
              <li>
                <router-link to="/structure">Структура</router-link>
              </li>
              <li>
                <router-link to="/life">Безбар'єрність</router-link>
              </li>
              <li>
                <router-link to="/news">Новини</router-link>
              </li>
            </ul>
            <div
              @click="toggleMenuSlider"
              class="burger-menu"
              style="background-image: url('/assets/contacts/menu.png');"
            ></div>
          </nav>
        </div>
      </div>

      <div class="wrap-box">
        <div class="intro-title">
          <h1>
            Запис до
            <span class="highlighted">лікаря</span>
          </h1>
          <p>
            Запишіться на прийом до свого сімейного лікаря,
            <br />уникаючи довгих черг.
          </p>
          <div class="intro-title-action">
            <button class="action">
              <a href="https://helsi.me/find-by-organization/org_13/divisions">Амбулаторії</a>
            </button>
            <button class="action">
              <a href="https://helsi.me/find-by-organization/org_13/doctors">Знайти лікаря</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Intro",
  methods: {
    toggleMenuSlider() {
      const menu = document.querySelector("#menu-slider");
      menu.classList.toggle("menu-closed");
    }
  }
};
</script>
<style scoped>
* {
  color: white;
}

header {
  width: 100vw;
  height: 100vh;
  position: relative;
  color: white;
}

.box {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
}

.wrap-box {
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
}

.highlighted {
  color: #71d1fd;
  /* color: #ff374be7; */
  /* color:#395CF6; */
}

.intro-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-image: url("../assets/mask.png"), url("../assets/intro4.jpg");
}

nav.nav {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-title-logo {
  height: 100px;
  width: 300px;
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/logo/logo.png");
}

.nav-title {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-logo {
  display: inline-block;
  width: 110px;
  height: 110px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/logo/logo-transparent-bg.png");
}

.title-text {
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.title-text h1 {
  font-size: 35px;
}

.title-text span {
  font-size: 17px;
  opacity: 0.65;
}

.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 850px;
  height: 100%;
  flex-wrap: wrap;
}

.nav-links a {
  display: block;
  height: 100%;
  color: rgba(31, 69, 255, 0.55);
  /* color:rgb(18, 174, 247); */
  width: auto;
  white-space: nowrap;
  font-size: 21px;
}

.nav-links a.router-link-active {
  color: #f30722;
}

.burger-menu {
  display: none;
}

.intro-title {
  margin: 14vh auto;
  max-width: 700px;
}

.intro-title h1 {
  font-size: 46px;
  margin: 20px auto;
}

.intro-title p {
  font-size: 19px;
  opacity: 0.75;
}

.intro-title-action {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 45px auto;
}

button.action {
  border: none;
  outline: none;
  font-size: 19px;
  text-align: center;
  padding: 5px;
  margin: 0 10px;
  width: 190px;
  height: 45px;
  border-radius: 4px;
  opacity: 0.8;
  transition: all 0.35s;
}

button.action a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

button.action:hover {
  opacity: 1;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15),
    0 8px 24px 0 rgba(55, 74, 126, 0.2);
}

button.action:first-of-type {
  /* background: #293863; */
  /* background: #365AFD; */
  background: #294ccc;
}

button.action:last-of-type {
  /* background: #71d1fd; */
  background: #61c2ef;
  color: black;
}

button.action:focus {
  outline: none;
}

@media screen and (max-width: 1200px) {
  .nav-title {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
  }

  .title-logo {
    width: 100px;
    height: 100px;
  }

  .title-text {
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .title-text h1 {
    font-size: 30px;
  }

  .title-text span {
    font-size: 16px;
    opacity: 0.65;
  }

  .nav-links {
    margin-right: 10px;
    width: auto;
  }

  .nav-links a {
    display: block;
    height: 100%;
    width: auto;
    white-space: nowrap;
    font-size: 18px;
    margin: 0 8px;
  }

  .intro-title h1 {
    font-size: 46px;
    margin: 20px auto;
  }

  .intro-title p {
    font-size: 19px;
    opacity: 0.75;
  }

  button.action {
    padding: 5px;
    margin: 0 10px;
    width: 190px;
    height: 45px;
  }

  button.action a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1070px) {
  .nav-title {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
  }

  .title-logo {
    width: 100px;
    height: 100px;
  }

  .title-text h1 {
    font-size: 26px;
  }

  .title-text span {
    font-size: 15px;
    text-align: left;
    opacity: 0.65;
  }

  .nav-links {
    display: none;
  }

  .burger-menu {
    display: block;
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: invert(100%);
  }

  .intro-title {
    margin: 9vh auto;
  }

  .intro-title h1 {
    font-size: 38px;
    margin: 20px auto;
  }

  .intro-title p {
    font-size: 17px;
    opacity: 0.75;
  }

  .intro-title-action {
    margin: 30px auto 50px auto;
  }

  button.action {
    font-size: 17px;
    padding: 4px;
    margin: 0 10px;
    width: 170px;
    height: 38px;
    border-radius: 4px;
  }
}
</style>
