<template>
  <div class="news-block">
    <div class="wrap">
      <div class="photo-container">
        <div class="photo-heading">
          <span @click.prevent="redirectToLife">Безбар'єрність</span>
        </div>
        <div class="photo-box">
          <div
            v-for="(photo, i) in photosLifestyle"
            :key="i"
            class="photo-item"
            :style="`background-image: url(${photo})`"
          ></div>
        </div>
      </div>
      <div class="partners-container">
        <span class="partners-heading">Корисні посилання</span>
        <div class="partner-links">
          <a
            class="pertner-link"
            v-for="(partner, i) in partners"
            :key="i"
            :href="partner.link"
            target="_blank"
            :style="`background-image: url(${partner.img})`"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewsAndPartners",
  data: () => ({
    partners: [
      {
        title: "Деснянська районна в місті Києві державна адміністрація",
        link: "https://desn.kyivcity.gov.ua/",
        img: "/assets/contacts/rda.png"
      },
      {
        title: "Київська міська державна адміністрація",
        link: "https://kyivcity.gov.ua/",
        img: "/assets/contacts/kmda.png"
      },
      {
        title: "Міністерство охорони здоров'я",
        link: "https://moz.gov.ua/",
        img: "/assets/contacts/moz.jpg"
      },
      {
        title: "Національна служба здоров'я України",
        link: "https://nszu.gov.ua/",
        img: "/assets/contacts/nzsu.jpg"
      }
    ],
    photosLifestyle: [
      "/assets/lifestyle/2.jpg",
      "/assets/lifestyle/3.jpg",
      "/assets/lifestyle/6.jpg"
    ]
  }),
  methods: {
    redirectToLife() {
      window.scrollTo(0, 0);
      this.$router.push("/life");
    }
  }
};
</script>
<style scoped>
.news-block {
  width: 100vw;
  height: auto;
  min-height: 400px;
  margin-bottom: 12vh;
  background: #a4d4f2dc;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.news-block .wrap {
  margin: 0 auto;
  max-width: 1250px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.photo-container {
  width: 60%;
  flex-direction: column;
  /* background: #e0e6eb; */
  align-items: flex-start;
  margin: 20px auto 20px 15px;
}

.photo-heading {
  display: flex;
  height: 60px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.photo-heading span {
  font-size: 24px;
  color: #323335;
  font-weight: 700;
  cursor: pointer;
}

.photo-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: calc(100% - 60px);
}

.photo-item {
  height: 100%;
  width: 40%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
  margin-left: -10%;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.25),
    0 8px 24px 0 rgba(55, 74, 126, 0.4);
  filter: grayscale(40%);
}

.partners-container {
  display: flex;
  width: 30%;
  height: auto;
  flex-direction: column;
  background: #ffffff;
  align-items: center;
  margin: 20px 15px 20px auto;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15),
    0 8px 24px 0 rgba(55, 74, 126, 0.2);
}

.partners-heading {
  display: flex;
  width: 100%;
  margin: 0 auto;
  background: transparent;
  height: 60px;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgb(41, 76, 204);
  font-size: 19px;
}

.partner-links {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  background: #ffffff;
  align-items: center;
}

.partners-container a {
  display: flex;
  width: 180px;
  height: 170px;
  margin: 0;
  background: transparent;
  transition: all 0.3s;
  justify-content: center;
  align-items: center;
  color: black;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1200px) {
  .news-block .wrap {
    width: 94%;
    justify-content: space-around;
  }

  .photo-container {
    width: 50%;
    flex-direction: column;
    align-items: center;
    margin: 20px auto 20px 15px;
  }

  .photo-box {
    flex-direction: column;
    align-items: center;
    height: calc(100% - 60px);
  }

  .photo-item {
    height: 100%;
    width: 100%;
    clip-path: unset;
    margin-left: 0;
  }

  .partners-container {
    width: 38%;
    align-items: center;
  }

  .partners-heading {
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 60px;
    justify-content: center;
  }

  .partners-container a {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 540px) {
  .news-block .wrap {
    width: 94%;
    flex-direction: column;
  }

  .photo-container {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 20px auto 50px auto;
  }

  .photo-box {
    height: auto;
  }

  .photo-item {
    height: 30vh;
    width: 100%;
  }

  .partners-container {
    width: 100%;
    align-items: center;
  }
}
</style>