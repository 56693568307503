<template>
  <div class="news-block">
    <h2 class="heading">Новини закладу</h2>
    <span @click.prevent="redirectToNews" class="subheading">Переглянути усі дописи</span>
    <div class="news-container">
      <router-link
        v-for="(newsItem, index) in news"
        :key="index"
        :to="'/news/' + newsItem.slug"
        class="news-item"
      >
        <div class="news-item-image" :style="`background-image: url('${newsItem.image}')`"></div>
        <span class="news-item-title">{{newsItem.title}}</span>
        <div class="news-item-date">{{showDate(newsItem.date)}}</div>
      </router-link>
      <div @click.prevent="redirectToNews" class="news-item">
        <div
          class="news-item-image"
          style="background-image: url('/assets/contacts/news-general.jpg')"
        ></div>
        <span class="news-item-title">Архів новин</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewsLifestyle",
  data: () => ({
    news: [
      {
        title: "",
        image: "",
        slug: "",
        date: ""
      },
      {
        title: "",
        image: "",
        slug: "",
        date: ""
      },
      {
        title: "",
        image: "",
        slug: "",
        date: ""
      },
      {
        title: "",
        image: "",
        slug: "",
        date: ""
      },
      {
        title: "",
        image: "",
        slug: "",
        date: ""
      }
    ]
  }),
  async mounted() {
    const json = await fetch("/api/v1/news/?sort=-date&limit=5");
    const { data } = await json.json();
    console.log(data);
    this.news = data;
  },
  methods: {
    showDate(string) {
      if (string == "") return;
      let formatter = new Intl.DateTimeFormat("ru", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      });

      const date = new Date(string);
      return formatter.format(date);
    },
    redirectToNews() {
      window.scrollTo(0, 0);
      this.$router.push("/news");
    }
  }
};
</script>
<style scoped>
.news-block {
  width: 100vw;
  height: auto;
  margin-bottom: 12vh;
}

.news-block .heading {
  font-size: 34px;
  color: #222529;
  opacity: 0.8;
  margin-bottom: 12px;
}

.news-block .subheading {
  display: block;
  margin-bottom: 50px;
  color: #fd011a;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}

.news-container {
  max-width: 1250px;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.news-item {
  display: block;
  text-transform: none;
  color: inherit;
  cursor: pointer;
  min-width: 30%;
  width: 300px;
  height: 100%;
  text-align: left;
  margin: 20px auto;
}

.news-item:hover .news-item-image {
  filter: grayscale(70%);
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.7),
    6px 9px 18px 0 rgba(0, 0, 0, 0.4), 10px 15px 26px 0 rgba(0, 0, 0, 0.25);
}

.news-item:hover .news-item-date {
  color: #293863e5;
  opacity: 1;
}

.news-item-image {
  width: 100%;
  height: 190px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px;
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.35),
    5px 8px 15px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  background-color: #c3c3c3;
}

.news-item-title {
  display: block;
  font-size: 20px;
  color: #333;
  margin: 25px 0 12px 0;
  font-weight: 500;
}

.news-item-date {
  color: #3192bf;
  font-size: 17px;
  opacity: 0.85;
}
</style>