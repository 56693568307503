<template>
  <div class="services">
    <div class="services-intro">
      <h2>Наша місія</h2>
      <p>
        Наша місія – надання доступних, своєчасних, якісних медичних послуг на первинному рівні медичної допомоги шляхом формування відносин, що ґрунтуються на взаємоповазі та довірі, комфортних і безпечних умовах та професійності наших працівників.
      </p>
      <a href="https://helsi.me/clinic/org_13/divisions" class="intro-more">Дивитись структуру</a>
    </div>
    <div class="services-list">
      <div v-for="(item, i) in services" :key="i" class="service-item">
        <div :style="`background-image: url('${item.icon}')`" class="service-img"></div>
        <div class="service-title">{{item.title}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Services",
  data: () => ({
    services: [
      {
        // title: "Загальна практика – сімейна медицина",
        // icon: "/assets/services/stethoscope.png"
      },
      {
        // title: "Педіатрія",
        // icon: "/assets/services/newborn.png"
      },
      {
        // title: "Черговий кабінет",
        // icon: "/assets/services/ambulance.png"
      },
      {
        // title: "Сестринська справа",
        // icon: "/assets/services/nurse.png"
      },
      {
        // title: "Діагностика",
        // icon: "/assets/services/cardiac-graphic.png"
      },
      {
        // title: "Вакцинація",
        // icon: "/assets/services/blood.png"
      },
      {
        // title: "COVID-19",
        // icon: "/assets/services/coronavirus.png"
      },
      {
        // title: "Профілактика захворювань",
        // icon: "/assets/services/healthy-lifestyle.png"
      }
    ]
  }),
  async mounted() {
    const data = await fetch("/api/v1/serviceIcons/");
    const icons = await data.json();
    this.services = icons.data;
  }
};
</script>
<style scoped>
.services {
  max-width: 1250px;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.services-intro,
.services-list {
  transform: translateY(-42%);
  border-radius: 6px;
  display: flex;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15),
    0 8px 24px 0 rgba(55, 74, 126, 0.2);
}

.services-intro {
  width: 28%;
  flex-direction: column;
  background: #51b2df;
  justify-content: space-between;
  padding: 15px;
  margin-left: 15px;
  align-items: center;
}

.services-intro h2 {
  color: white;
  font-size: 28px;
  margin: 10px auto 20px auto;
  text-align: center;
}

.services-intro p {
  color: black;
  width: 85%;
  margin: auto;
  text-align: left;
  font-size: larger;
}

.services-intro a.intro-more {
  border: none;
  /* background: #293863; */
  /* background: #ff122ad0; */
  background: #294ccc;
  color: white;
  outline: none;
  font-size: 18px;
  text-align: center;
  padding: 5px;
  margin: 20px auto 0 auto;
  height: 50px;
  border-radius: 4px;
  transition: all 0.35s;
  width: 80%;
  max-width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-intro a.intro-more:hover {
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15),
    0 8px 24px 0 rgba(55, 74, 126, 0.2);
}

.services-list {
  width: 65%;
  flex-direction: row;
  background: #e0e6eb;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
}

.service-item {
  display: inline-block;
  width: 170px;
  max-height: 160px;
  height: 100%;
  margin: 15px auto;
  padding: 10px;
  background: white;
  border-radius: 6px;
  transition: all 0.35s;
}

.service-item:hover {
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15),
    0 8px 24px 0 rgba(55, 74, 126, 0.2);
}

.service-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 80px;
  margin: 0 auto;
  height: 80px;
  margin-bottom: 20px;
}

.service-title {
  padding-bottom: 4px;
  margin: 0 auto 0 auto;
  font-size: 14.5px;
}

@media screen and (max-width: 1050px) {
  .services {
    max-width: 100vw;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .services-intro,
  .services-list {
    max-width: 760px;
    width: calc(100%-30px);
    margin: 40px 15px 0 15px;
  }

  .services-intro {
    transform: translateY(-82%);
    width: 94%;
    flex-direction: column;
    background: #51b2df;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
  }

  .services-intro p {
    color: black;
    width: 94%;
    margin: auto;
    text-align: left;
  }

  .services-list {
    transform: translateY(-30%);
    width: 94%;
    flex-direction: row;
    background: #e0e6eb;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .service-item {
    height: 170px;
    max-height: unset;
    margin: 15px auto;
    padding: 10px;
  }
}

@media screen and (max-width: 730px) {
  .services {
    max-width: 100vw;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .services-intro {
    transform: translateY(-70%);
    width: 94%;
    max-width: 530px;
    flex-direction: column;
    background: #51b2df;
    justify-content: space-between;
    padding: 15px;
  }

  .services-intro p {
    color: black;
    width: 94%;
    margin: auto;
    text-align: left;
  }

  .services-list {
    transform: translateY(-20%);
    width: 94%;
    max-width: 528px;
    flex-direction: row;
    background: #e0e6eb;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .service-item {
    width: 190px;
    max-height: unset;
    margin: 15px auto;
    padding: 10px;
  }
}

@media screen and (max-width: 420px) {
  .services-intro {
    transform: translateY(-50%);
  }

  .services-intro h2 {
    font-size: 24px;
  }

  .services-intro p {
    width: 85%;
  }

  .services-list {
    transform: translateY(-5%);
  }

  .service-item {
    max-width: 250px;
    width: 96%;
    max-height: unset;
    margin: 15px auto;
    padding: 10px;
  }
}
</style>
