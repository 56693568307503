<template>
  <div>
    <Intro />
    <Services />
    <NewsAndPartners />
    <NewsLifestyle />
    <FeedbackForm />
    <div class="social-buttons">
      <a
        href="https://www.facebook.com/cpmsd2desna/"
        style="background-image: url('/assets/contacts/facebook-button.png');"
        target="_blank"
        class="social-button social-button-facebook"
      ></a>
      <a
        href="tel:+38(073) 537 1560"
        style="background-image: url('/assets/contacts/phone-button.png');"
        class="social-button social-button-phone"
      ></a>
    </div>
  </div>
</template>

<script>
import Intro from "@/components/Intro";
import Services from "@/components/Services";
import NewsLifestyle from "@/components/NewsLifestyle";
// import Footer from "@/components/Footer";
import NewsAndPartners from "@/components/NewsAndPartners";
import FeedbackForm from "@/components/FeedbackForm";

export default {
  name: "Home",
  components: {
    Intro,
    Services,
    NewsLifestyle,
    // Footer,
    NewsAndPartners,
    FeedbackForm
  }
};
</script>
<style scoped>
.social-buttons {
  position: fixed;
  width: 60px;
  height: 140px;
  right: 20px;
  bottom: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.social-button {
  width: 60px;
  height: 60px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.7),
    6px 9px 18px 0 rgba(0, 0, 0, 0.4), 10px 15px 26px 0 rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
</style>